import { Component, Vue, Watch } from 'vue-property-decorator';
import LocationService from '@/services/LocationService';
import ResponseCfcDTO from '@/models/ResponseCfcDTO';
@Component({ })
class LocationMixin extends Vue {
    
    public inputTimeout: number = -1;
    public locations: ResponseCfcDTO[] = [];
    public showResultBox: boolean = false;
    public firstLoad: boolean = true;
    public loadingLocation: boolean = false;
    public searchValue: string | null = this.$store.getters['getSearchValue'] || "";
    private canceler: (() => void) | null = null;

    public get position(): [number, number] | null {
        return this.$store.getters['getCurrentPosition'];
    }

    public buscarEnderecos(search: string){
        this.firstLoad = false;
        this.locations = [];
        this.loadingLocation = true;
        const position = this.position != null ? { lat: this.position[1], lng: this.position[0] } : null;
        const [request, canceler] = LocationService.SearchAddress(search.trim(), position);
        this.canceler = canceler;
        request
            .then(resp => {
                this.locations = resp;
            })
            .finally(() => this.loadingLocation = false);
    }

    @Watch('searchValue')
    public onSearchChange(value: string) {
        clearTimeout(this.inputTimeout);
        this.canceler && this.canceler();
        this.$store.commit('setSearchValue', value);

        (this.inputTimeout as any) = setTimeout(() => {
            if(value.length) {
                this.buscarEnderecos(value);
            }
        }, 1200);
    }

    public clearLocations() {
        this.$store.commit('setSearchValue', '');
        this.searchValue = '';
        this.locations = [];
    }

}

export default LocationMixin;