
import { Component, Vue, Prop } from 'vue-property-decorator';
import boldString from '@/utils/boldString';
import ResponseCfcDTO from '@/models/ResponseCfcDTO';

@Component({ filters: { boldString } })
class BuscaAutoescolaRow extends Vue {
    private boldString = boldString;

    @Prop({ type: Object, required: true })
    private location!: ResponseCfcDTO;

    @Prop({ type: String, required: true })
    private searchValue!: string;

    private get subtitle() {
        let location = '';
        if(this.location.endereco.bairro)
            location += `${this.location.endereco.bairro}, `;
        if(this.location.endereco.cidade)
            location += `${this.location.endereco.cidade} `;
        if(this.location.endereco.uf) 
            location += `- ${this.location.endereco.uf}`;

        return location;
    }

    private select() {
        this.$emit('selected', [this.location.longitude, this.location.latitude]);
    }
}

export default BuscaAutoescolaRow;
