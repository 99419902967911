import removerAcento from './removerAcento';
export default function boldString(text: string, textToCompare: string): string {
    const textoSemAcento = removerAcento(text.toLowerCase().trim());
    const pesquisaSemAcento =removerAcento(textToCompare.toLowerCase().trim());

    const hasWord = textoSemAcento.search(new RegExp(pesquisaSemAcento, 'gi'));
    if(hasWord >= 0 && textToCompare.length > 0){
        return `${text.substr(0, hasWord)}<b>${text.substr(hasWord, textToCompare.length)}</b>${text.substring(hasWord + textToCompare.length, text.length)}`;
    }
    return text;
}