
import { Component, Vue, Prop } from 'vue-property-decorator';
import ResponseCfcDTO from '../../models/ResponseCfcDTO';

@Component({})
class CardAutoescola extends Vue {
    @Prop({ type: Object, required: true })
    public readonly cfc!: ResponseCfcDTO;

    public get position(): [number, number]{
        return this.$store.getters['getCurrentPosition'];
    }

    // public get avaliacoesAmigos(){
    //     return this.cfc.avaliacoesAmigos.slice(0, 4);
    // }

    // public get distanceFormatted(){
    //     return this.cfc.distancia.toString().replace('.',',');
    // }

    // public get avaliacoesString(){
    //     const length = this.cfc.avaliacoesAmigos.length;
    //     if(length == 1){
    //         return '1 amigo avaliou';
    //     }else if(length > 1){
    //         return `${length} amigos avaliaram`;
    //     }
    //     return 'Ainda não há avaliação de amigos';
    // }
}

export default CardAutoescola;
