
import CfcPremiumDTO from '@/DTOs/CfcPremiumDTO';
import EstatisticasDTO from '@/DTOs/EstatisticasDTO';
import Cfc, { HorarioFuncionamento } from '@/models/Cfc';
import { OpeningHoursPeriod } from '@/models/GooglePlaceDetailDTO';
import CompanyDTO from '@/modules/core/DTOs/companies/CompanyDTO';
import CompaniesService from '@/modules/core/services/CompaniesService';
import RatingResponseDTO from '@/modules/rating/DTOs/ratings/RatingResponseDTO';
import SurveyStatsDTO from '@/modules/rating/DTOs/surveys/SurveyStatsDTO';
import RatingsService from '@/modules/rating/services/RatingsService';
import FileDTO from '@/modules/storage/DTOs/FileDTO';
import FilesService from '@/modules/storage/services/FilesService';
import { trackers } from '@/plugins/insights';
import vClickOutside from 'v-click-outside';
import { MglMap, MglMarker } from "vue-mapbox";
import { Component, Vue, Watch } from 'vue-property-decorator';
import ResponseCfcDTO from '../../../models/ResponseCfcDTO';
import CfcService from '../../../services/CfcService';
import LocationService from '../../../services/LocationService';

@Component({
    components: { MglMap, MglMarker },
    directives: { 'click-out': vClickOutside.directive } })

class DetalheAutoescola extends Vue {

    public stats: SurveyStatsDTO | null = null;
    public ratings: RatingResponseDTO | null = null;
    public company: CompanyDTO | null = null;
    public cfcPilotar: Cfc | null = null;
    public horariosFuncionamentoGoogle: OpeningHoursPeriod[] | null = null;
    public file: FileDTO | null = null;

    public getServicos() {
        return this.company == null ? [] : this.company.services;
    }

    public getHorariosFuncionamentoGoogle() {
        return this.horariosFuncionamentoGoogle ?? [];
    }

    public formatGoogleTime (time: string): string {
        return `${time.substr(0, 2)}:${time.substr(2, 2)}`;
    }

    public formatDate(date: string){
        const dd: Date = new Date(date);
        const d = dd.getDate();
        const m = dd.getMonth() + 1;
        const y = dd.getFullYear();

        return `${d.toString().length == 1 ? "0" : ""}${d}/${m.toString().length == 1 ? "0" : ""}${m}/${y}`;
    }

    public openRatingDetails() {
        if (this.cfcPilotar != null && this.sourceRatings == 'Pilotar') {
            this.showRatingDetails = true;
            this.loadRatings();
        }
    }

    public async loadRatings() {
        try{
            this.loadingRatings = true;
            this.ratings = await RatingsService.getRatingsByCNPJ(this.cfcPilotar?.cnpj ?? '', '');
        } catch(e){ console.log(e); }
        finally {
            this.loadingRatings = false;
        }
    }
    
    public async loadMoreRatings() {
        try{
            this.loadingRatings = true;
            const nextPage = await RatingsService.getRatingsByCNPJ(this.cfcPilotar?.cnpj || '', this.ratings?.pagination.nextPage || '');
            if (this.ratings?.data) {
                this.ratings.data = this.ratings.data.concat(nextPage.data);
                this.ratings.pagination = nextPage.pagination;
            }
        } catch(e){ console.log(e); }
        finally {
            this.loadingRatings = false;
        }
    }
    
    private async mounted(){

        // Insights
        trackers.trackPage(this.$route.name!, this.$route.fullPath, { cfcId: this.cfcSelecionado.googleId ?? this.cfcSelecionado.cfcId, fromGoogle: this.cfcSelecionado.googleId != null });

        // ler mapa
        setTimeout(() => {
            this.loaded = true;
        }, 500);


        try{
            this.cfcPilotar = await CfcService.GetByGooglePlaceId(this.cfcSelecionado.googleId);
        } catch(e){ console.log(e); }

        try{
            this.stats = await RatingsService.getSurveyStatsByCNPJ(this.cfcPilotar?.cnpj ?? '');
        } catch(e){ console.log(e); }


        let company: CompanyDTO | null = null;

        if (this.cfcPilotar?.cnpj != null && this.cfcPilotar?.cnpj != ""){
            company = await CompaniesService.getByIdentityDocument("cnpj", this.cfcPilotar?.cnpj);
            try{
                // this.perfil = empresa;
                this.company = company;
            } catch(e){ console.log(e); }
        }

        if (company?.avatarFileId != null) {
            try{
                this.file = await FilesService.getById(company.avatarFileId, true);
            } catch(e){ console.log(e); }
        }

        const horariosFuncionamento = this.company?.weekdays?.map(day => {
            return { diaSemana: this.nomeDiaSemana(day.id), horaInicio: "08:00", horaFim: "17:00" } as HorarioFuncionamento;
        })!;
               
        const carteirasOferecidas = this.company?.tiposHabilitacao?.map(tipo => tipo.sigla)!;
               
        // Se não for localização do google
        if(this.cfcSelecionado.googleId == null) {
            CfcService.GetCfcById(this.cfcSelecionado.cfcId)
                .then(cfc => {
                    this.cfc = cfc;
                    return CfcService.GetAvaliacoesCfc(this.cfcSelecionado.cfcId);
                })
                .then(avaliacoes => this.estatisticas = avaliacoes)
                .catch(error => this.error = error)
                .finally(() => this.loading = false);
        }
        // Se for do Google
        else {
            LocationService.GetGooglePlaceDetail(this.cfcSelecionado.googleId)
                .then(resp => {
                    this.estatisticas = resp.avaliacoesGoogle ? [{
                        descricaoAvaliacao: 'Avaliações',
                        quantidadeComentarios: (this.stats?.count ?? 0) > 0 ? this.stats?.count ?? 0 : (resp.avaliacoesGoogle?.length ?? 0),
                        quantidadeAvaliacoes: (this.stats?.count ?? 0) > 0 ? this.stats?.count ?? 0 : (resp.avaliacoesGoogle?.length ?? 0),
                        mediaNota: this.stats != null ? this.stats.avgDecimal : (resp.notaGoogle ? parseInt(resp.notaGoogle) : 0),
                        source: (this.stats?.count ?? 0) > 0 ? 'Pilotar' : 'Google',
                        groupByNotas: []
                    }] : [];

                    this.horariosFuncionamentoGoogle = resp.horariosFuncionamento?.periods;

                    this.cfc = {
                        id: 0,
                        token: '',
                        razaoSocial: this.cfcPilotar?.razaoSocial ?? resp.displayName,
                        cnpj: this.cfcPilotar?.cnpj ?? "",
                        nomeFantasia: this.cfcPilotar?.nomeFantasia ?? resp.displayName,
                        descricao: this.company?.description ?? "",
                        email: this.cfcPilotar?.email ?? "",
                        mediaNota: this.stats != null ? this.stats.avgDecimal : (resp.notaGoogle ? parseInt(resp.notaGoogle) : 0),
                        carteirasOferecidas: carteirasOferecidas ?? [],
                        isVeiculosDeficiente: false,
                        isAulaDeficienteAuditivo: false,
                        senha: '',
                        endereco: {
                            cep: this.cfcPilotar?.endereco?.cep ?? resp.endereco.cep,
                            logradouro: this.cfcPilotar?.endereco?.logradouro ?? resp.endereco.logradouro,
                            complemento: this.cfcPilotar?.endereco?.complemento ?? "",
                            bairro: this.cfcPilotar?.endereco?.bairro ?? resp.endereco.bairro,
                            numero: this.cfcPilotar?.endereco?.numero ?? resp.endereco.numero,
                            localidade: this.cfcPilotar?.endereco?.localidade ?? resp.endereco.cidade,
                            uf: this.cfcPilotar?.endereco?.uf ?? resp.endereco.uf,
                            latitude: resp.latitude,
                            longitude: resp.longitude
                        },
                        horarioFuncionamento: horariosFuncionamento,
                        observacao: this.cfcPilotar?.observacao ?? "",
                        imagens: [],
                        logo: '',
                        isSuperPratico: false,
                        telefone: this.cfcPilotar?.telefone ?? (resp.telefone ?? ""),
                        whatsapp: this.cfcPilotar?.whatsapp ?? "",
                        urlRemote: '',
                        companyId: '',
                        loginConpay: '',
                        senhaConpay: '',
                        googlePlaceId: this.cfcPilotar?.googlePlaceId ?? ""
                    };
                })
                .catch(error => this.error = error)
                .finally(() => this.loading = false);
        }
    }

    public loading: boolean = true;
    public loadingRatings: boolean = false;
    public loaded = false;
    public showPopUp: boolean = false;
    public showRatingDetails: boolean = false;
    public hoverScroll: boolean = false;
    public stage: number = 2;
    public error: string = '';
    public selectedImageIndex: number = 0;
    public galleryOpened: boolean = false;
    public remoteModalOpened: boolean = false;

    // ======= Dados =========
    public cfc: Cfc | null = null;
    public estatisticas: EstatisticasDTO[] = [];


    // ===== Variáveis computadas ==========
    public get cfcSelecionado(): ResponseCfcDTO{
        return this.$store.getters['GetCfcSelecionado'];
    }

    public get cfcPremiumSelecionado(): CfcPremiumDTO {
        return this.$store.getters['GetCfcPremiumSelecionado'];
    }

    public get ripple(): string {
        return this.cfcPilotar == null ? "" : "v-ripple";
    }

    public get imagensCfc(){
        if(this.cfc != null) {
            return this.cfc.imagens.map(el => `https://pilotar.blob.core.windows.net/pilotar/${el.url}`);
        }
        else {
            return [];
        }
    }

    public get nomeCFC() {
        return this.company?.name ?? (this.cfc != null ? this.cfc.nomeFantasia : this.cfcSelecionado.nomeFantasia);
    }

    public get mediaNota() {
        return this.cfc?.mediaNota != null ? this.cfc?.mediaNota.toFixed(1).replace(".", ",") : '0,0';
    }

    public get mediaNotaNumber(): number {
        const media = this.estatisticas == null || this.estatisticas.length == 0 ? 0 : this.estatisticas[0].mediaNota;
        return media ?? 0;
    }

    public get sourceRatings(): string {
        return this.estatisticas == null || this.estatisticas.length == 0 ? '' : this.estatisticas[0].source;
    }

    public get avaliacoesString(){
        if(this.estatisticas != null && this.estatisticas.length > 0) {
            const length = this.estatisticas.reduce((_total, current) => {
                return current.quantidadeAvaliacoes;
            }, 0);
            if(length == 1){
                return `(1) Avaliação no ${this.estatisticas[0].source}`;
            }else if(length > 1){
                return `(${length}) Avaliações no ${this.estatisticas[0].source}`;
            }
            return 'Ainda não há avaliações';
        }
        else {
            return '';
        }
       
    }

    public get avaliacoesString2(){
        if(this.cfc != null && this.estatisticas != null) {
            const length = this.estatisticas.reduce((_total, current) => {
                return current.quantidadeAvaliacoes;
            }, 0);
            if(length == 1){
                return '1 Avaliação no total';
            }else if(length > 1){
                return `${length} avaliações no total`;
            }
            return 'Ainda não há avaliações';
        }
        else {
            return '';
        }
       
    }
    // ========== Métodos =============
    public swipeTop(){
        if (!this.showRatingDetails) {
            this.showPopUp = false;
            if(this.stage > 1) {
                this.stage--;
            }
        }
    }

    public swipeBottom(){
        if (!this.showRatingDetails) {
            if(this.stage < 3) {
                const content = this.$refs.content as Vue;
                if(!this.hoverScroll || content.$el.scrollTop == 0) {
                    this.stage++;
                }
            }
        }
    }

    public resizeGuyClick() {
        if (this.stage === 3) {
            this.swipeTop();
        }
        else if (this.stage === 1) {
            this.swipeBottom();
        }
    }

    public closeButtonClicked(){
        this.stage == 3 && this.cfcSelecionado.cfcId > 0 ? this.stage = 2 : this.$store.commit('goBack');
    }

    public selectImage(index: number) {
        this.selectedImageIndex = index;
        this.galleryOpened = true;
    }

    public navigate(url: string) {
        trackers.registerTappedCallButton();
        window.location.assign(url);
    }

    public openAttendanceModal(url: string){
        trackers.registerTappedAttendanceButton();
        this.remoteModalOpened = true;
        setTimeout(() => {
            this.remoteModalOpened = false;
            this.navigate(url);
        }, 2000);
    }

    public get endereco() {
        let endereco: string | null = null;
        if (this.enderecoPilotar !== null) {
            endereco = this.enderecoPilotar;
        }
        else {
            endereco = this.enderecoGoogle;
        }
        return endereco;
    }

    public get enderecoPilotar() {
        if (this.company?.address?.street == null || this.company?.address?.street === "") {
            return null;
        }

        let address = this.company?.address?.street;
        if (this.company?.address?.buildingNumber && this.company?.address?.buildingNumber.toString() !== ""){
            address = `${address}, ${this.company?.address?.buildingNumber}`;
        }
        if (this.company?.address?.district && this.company?.address?.district !== ""){
            address = `${address} ${this.company?.address?.buildingNumber && this.company?.address?.buildingNumber.toString() !== "" ? " - " : ""} ${this.company?.address?.district}`;
        }
        if (this.company?.address?.city && this.company?.address?.city !== ""){
            address = `${address}, ${this.company?.address?.city}`;
        }
        if (this.company?.address?.state && this.company?.address?.state !== ""){
            address = `${address} ${this.company?.address?.city && this.company?.address?.city !== "" ? " - " : ""} ${this.company?.address?.state.toUpperCase()}`;
        }
        if (this.company?.address?.zipCode && this.company?.address?.zipCode !== ""){
            address = `${address}, ${this.formatCep(this.company?.address?.zipCode)}`;
        }

        return address;
    }

    public get enderecoGoogle() {
        if (this.cfc?.endereco?.logradouro == null || this.cfc?.endereco?.logradouro === "") {
            return null;
        }

        let endereco = this.cfc?.endereco.logradouro;
        if (this.cfc?.endereco?.numero && this.cfc?.endereco?.numero !== ""){
            endereco = `${endereco}, ${this.cfc?.endereco?.numero}`;
        }
        if (this.cfc?.endereco?.bairro && this.cfc?.endereco?.bairro !== ""){
            endereco = `${endereco} ${this.cfc?.endereco?.numero && this.cfc?.endereco?.numero !== "" ? " - " : ""} ${this.cfc?.endereco?.bairro}`;
        }
        if (this.cfc?.endereco?.localidade && this.cfc?.endereco?.localidade !== ""){
            endereco = `${endereco}, ${this.cfc?.endereco?.localidade}`;
        }
        if (this.cfc?.endereco?.uf && this.cfc?.endereco?.uf !== ""){
            endereco = `${endereco} ${this.cfc?.endereco?.localidade && this.cfc?.endereco?.localidade !== "" ? " - " : ""} ${this.cfc?.endereco?.uf.toUpperCase()}`;
        }
        if (this.cfc?.endereco?.cep && this.cfc?.endereco?.cep !== ""){
            endereco = `${endereco}, ${this.formatCep(this.cfc?.endereco?.cep)}`;
        }

        return endereco;
    }

    public formatCep(cep: string): string {
        return `${cep.substr(0, 5)}-${cep.substr(5, 3)}`;
    }

    public nomeDiaSemanaTela(dia: string, index: number) {
        const nome: string | undefined = this.nomeDiaSemana(dia);
        let nomeAnterior: string | undefined = "";
        if (index > 0) {
            nomeAnterior = this.nomeDiaSemana(this.getHorariosFuncionamentoGoogle()[index - 1].open.day.toString());
        }
        if (nomeAnterior == nome){
            return "";
        }
        return nome;
    }

    public nomeDiaSemana(dia: string) {
        if (dia === "0") return "Domingo";
        if (dia === "1") return "Segunda-feira";
        if (dia === "2") return "Terça-feira";
        if (dia === "3") return "Quarta-feira";
        if (dia === "4") return "Quinta-feira";
        if (dia === "5") return "Sexta-feira";
        if (dia === "6") return "Sábado";
    }

    // ======= Observadores ========
    @Watch('stage')
    onStageChange(newValue: number) {
        this.showPopUp = newValue == 3;
    }
}

export default DetalheAutoescola;
