
import { Component, Vue } from 'vue-property-decorator';
import { CfcFilters } from '@/store/modules/cfcStore';

@Component({})
class FiltroAutoescolasPage extends Vue {
    private beforeMount(){
        this.filters = { ...this.$store.getters['GetFilters'] };
    }

    private filters: CfcFilters = {
        orderBy: "distancia",
        isAcompanhamentoDigital: false,
        isAulaDeficienteFisico: false,
        isVeiculosDeficiente: false,
    };

    private resetFilters(){
        this.filters = {
            orderBy: "distancia",
            isAcompanhamentoDigital: false,
            isAulaDeficienteFisico: false,
            isVeiculosDeficiente: false,
        };
    }

    private applyFilters(){
        this.$store.commit('SetFilters', this.filters);
        this.$store.commit('goBack');
    }

    public servicesDisponiveis = ['A', 'B', 'C', 'D', 'E'];

}

export default FiltroAutoescolasPage;
