import { Get, GetById, Patch } from './BaseService';
import Cfc from '@/models/Cfc';
import DefaultQuery from '@/queryObjects/DefaultQuery';
import EstatisticasDTO from '@/DTOs/EstatisticasDTO';

export default class CfcService {

    public static BaseUrl = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_CFC_API_URL}`;
    public static BaseAvaliacaoUrl = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_AVALIACAO_API_PORT}`;

    public static GetCfcs(query: DefaultQuery): Promise<Cfc[]> {
        return Get<Cfc[]>(`${this.BaseUrl}/cfcs`, {
            pagina: query.pagina,
            tamanhoPagina: query.tamanhoPagina
        });
    }

    public static GetCfcById(id: number): Promise<Cfc> {
        return GetById<Cfc>(`${this.BaseUrl}/cfcs`, id);
    }

    public static GetByGooglePlaceId(id: string): Promise<Cfc> {
        return Get<Cfc>(`${this.BaseUrl}/cfcs/${id}/googleplaceid`);
    }

    public static GetAvaliacoesCfc(id: number) {
        return GetById<EstatisticasDTO[]>(`${this.BaseAvaliacaoUrl}/estatisticas`, id);
    }

    public static UpdateEvaluation(id: number) {
        return Patch<void, void>(`${this.BaseUrl}/cfcs`, id, { media: 0 });
    }

}