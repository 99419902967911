
import { Component, Mixins } from 'vue-property-decorator';
import PAutoescolaCard from '@/components/layouts/p-AutoescolaCard.vue';
import ResponseCfcDTO from '../../../models/ResponseCfcDTO';
import { CfcFilters } from '@/store/modules/cfcStore';
import ErrorView from '@/views/ErrorView.vue';
import BuscaAutoescolaRow from './BuscaAutoescolaRow.vue';
import AbrirMapaRow from './AbrirMapaRow.vue';
import vClickOutside from 'v-click-outside';
import ErrorIllustration from '@/components/illustrations/error.vue';
import LocationService from '@/services/LocationService';
import AuthResponse from '@/models/AuthResponse';
import NativeService from '@/services/NativeService';

// Mixins
import LocationMixin from '@/mixins/LocationMixin';
import { trackers } from '@/plugins/insights';

@Component({ 
    components: { PAutoescolaCard, ErrorView, BuscaAutoescolaRow, AbrirMapaRow, ErrorIllustration },
    directives: { 'click-out': vClickOutside.directive }
})
class AutoescolasPage extends Mixins(LocationMixin) {

    private get user(): AuthResponse {
        return this.$store.getters['getUser'];
    }

    private showModalGPS: boolean = false;

    private closeModal() {
        this.showModalGPS = false;
    }

    public async openMap(from: string){

        localStorage.setItem("openmap-from", from);

        const hnd: number = setTimeout(() => {
            this.loadingCoords = true;
        }, 1500);

        try {
            const coords = await NativeService.GetCurrentPosition();
            this.$store.commit('SetMapPosition', coords);
            this.$store.commit('navigate', { page:'buscarmapa', transition: 'fade' });
        }
        catch(error) {
            this.showModalGPS = true;
        }
        finally {
            clearTimeout(hnd);
            this.loadingCoords = false;
        }
    }

    private async mounted() {
        // Verifica se o usuário está retornando para a tela de pesquisa, vindo da tela de seleção da localização pelo mapa
        if (this.isPositionFromMap) {
            this.$store.commit('ClearCfcs');

            //Se o usuário não mudou sua localização no mapa, carrega os CFCs no formato "Minha Localização"
            //if ((resp[0] == mapposition[0] || resp[1] == mapposition[1])) {
            if (localStorage.getItem("openmap-from") === "localizacao") {
                this.loadLocalizacao(0);
            }
            //Se o usuário mudou sua localização no mapa, carrega os CFCs no formato "Endereço"
            else {
                this.loading = true;
                await this.usarLocalizacaoDoMapa();
            }
        }
        // Tela de pesquisa está sendo carregada pelo acesso inicial do aplicativo
        else if (this.cfcs.length == 0 && !this.isBlockedSearchOnLoad) {
            // Exibe a tela com orientação sobre o GPS caso não exista registro de coleta da coordenada do usuário
            const locationAllowed = localStorage.getItem("getlocationallowed#pilotarapp");
            if (locationAllowed == null || (locationAllowed != null && locationAllowed != "true")) {
                this.showModalGPS = true;
            }
            this.loadLocalizacao(2500);
        }
        this.$store.commit('setBlockedSearchOnLoad', false);
    }

    private loadLocalizacao(time: number) {
        this.loading = true;
        setTimeout(async () => {
            try {
                await this.$store.dispatch('LoadCurrentPosition');
                await this.usarLocalizacao();
            }
            catch(e) {
                this.$store.commit('setUserAllowedPosition', false);
                this.loading = false;
                this.showModalGPS = true;
            }
        }, time);
        setTimeout(async () => {
            this.loading = false;
        }, time * 2);
    }

    private async usarLocalizacao(){
        try {
            this.$store.commit('ClearCfcs');
            const resp: [number, number] = this.$store.getters['getCurrentPosition'] as [number, number];

            if (resp != null) {

                this.$store.commit('setCurrentPosition', resp);
                this.$store.commit('setUsingCurrentPosition', true);
                this.$store.commit('setUserAllowedPosition', true);

                const location: ResponseCfcDTO[] = await LocationService.SearchAddress(`${resp[1]}, ${resp[0]}`, null)[0];
                if (location != null && location.length > 0) {
                    this.$store.commit('setCurrentPositionAddress', location[0].nomeFantasia);
                }

                this.searchValue = "";
                this.$store.commit('setPositionFromMap', false);
                this.loadCfcs();
            }

        }
        catch(error) {
            this.$store.commit('setUserAllowedPosition', false);
            this.loading = false;
            this.showModalGPS = true;
        }
    }

    private async usarLocalizacaoDoMapa(){
        try {
            this.searchValue = "";
            if (this.$store.getters['getCurrentPosition'] !== null) {
                this.$store.commit('setUsingCurrentPosition', false);
                //this.$store.commit('setUserAllowedPosition', true);
                const resp: [number, number] = this.$store.getters['getCurrentPosition'];
    
                if (resp != null) {
                    const location: ResponseCfcDTO[] = await LocationService.SearchAddress(`${resp[1]}, ${resp[0]}`, null)[0];
                    if (location != null && location.length > 0) {
                        this.$store.commit('setCurrentPositionAddress', null);
                        this.searchValue = location[0].nomeFantasia;
                    }
                }
    
                this.$store.commit('setPositionFromMap', false);
                this.loadCfcs();
            }
        }
        catch(error) {
            console.log(error);
            this.loading = false;
        }
    }


    public get position(): [number, number] | null {
        return this.$store.getters['getCurrentPosition'];
    }

    private loading: boolean = false;
    private loadingCoords: boolean = false;
    private error: string = '';
    
    // ======== Propriedades computadas ==========
    private get cfcs(): ResponseCfcDTO[] {
        return this.$store.getters['GetCfcs'];
    }

    private get filters(): CfcFilters {
        return this.$store.getters['GetFilters'];
    }

    private get usingCurrentPosition(): boolean {
        return this.$store.getters['isUsingCurrentPosition'];
    }

    private get isCfcsLoaded(): boolean {
        return this.$store.getters['isCfcsLoaded'];
    }

    private get isBlockedSearchOnLoad(): boolean {
        return this.$store.getters['isBlockedSearchOnLoad'];
    }

    private get isPositionFromMap(): boolean {
        return this.$store.getters['isPositionFromMap'];
    }

    private get isFullWindow(): boolean {
        return this.$store.getters['isFullWindow'];
    }

    private get currentPositionAddress(): boolean {
        return this.$store.getters['getCurrentPositionAddress'];
    }

    // Cfcs Filtrados
    private get cfcsFiltered() {
        return this.cfcs.filter(cfc => {
            let match = true;
            if(match && this.filters.isAcompanhamentoDigital) match = cfc.isAcompanhamentoDigital;
            if(match && this.filters.isAulaDeficienteFisico) match = cfc.isAulaDeficienteFisico;
            if(match && this.filters.isVeiculosDeficiente) match = cfc.isVeiculosDeficiente;
            return match;
        });
    }

    // Cfcs Ordenados
    private get cfcsOrdened() {
        return this.cfcsFiltered.sort((cfc1, cfc2) => +cfc1.distancia - +cfc2.distancia);
    }

    // ========= Métodos ==========
    private selectedLocation(coordinates: [number, number]) {
        // Insights
        trackers.registerSearchedCfc();

        this.$store.commit('setUsingCurrentPosition', false);
        this.$store.commit('setCurrentPositionAddress', null);
        
        this.$store.commit('setCurrentPosition', coordinates);
        this.loadCfcs();
    }
    // Carregar Cfcs
    private loadCfcs(){
        this.loading = true;
        this.error = '';
        this.$store.dispatch('LoadCfcs')
            .then(() => this.loading = false)
            .catch(error => this.error = error)
            .finally(() => this.loading = false);
    }

    // Selecionar CFCs
    private async selectCfc(cfc: ResponseCfcDTO){
        this.$store.commit('SetCfcSelecionado', cfc);
        this.$store.commit('navigate', { page: 'detalhe-autoescola', transition: 'toUp' });
    }
}

export default AutoescolasPage;
